@import url('src/assets/fonts/satoshi/stylesheet.css');
@import url('src/branding/redis/redis.css');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Satoshi', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500 !important;
  font-size: 16px !important;
  /* Sometimes content can fit exactly in the document so there won't be any
  scroll events to trigger InfiniteScroll to request the next page so we add 1px
  so there will be a scroll event to trigger request for the next page */
  min-height: calc(100vh + 1px) !important;
  height: auto !important;
}

code {
  font-family: 'Satoshi' !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Satoshi', sans-serif !important;
}

.expandable-paragraph > * {
  overflow: hidden;
}

.conversation-message .ant-comment-avatar {
  cursor: default;
}

.fj-navbar-drawer .ant-drawer-close {
  color: #ffffff;
}

.fj-navbar-drawer .ant-drawer-close:hover {
  color: grey;
}

.conversations-multi-avatar-left > img {
  overflow: hidden;
  width: 50% !important;
}

.conversations-multi-avatar-right > img {
  overflow: hidden;
  margin-left: 50% !important;
  padding-right: 50% !important;
}

.fj-expandable-row {
  cursor: pointer;
}

/* antd Form css START */
.ant-form-item-has-error .ant-input {
  border-color: #ff5b37 !important;
}
.ant-form-item-explain-error {
  margin-top: 8px;
  color: #ff5b37 !important;
}

.ant-form-item-margin-offset {
  margin-bottom: -16px !important;
}

.ant-input-prefix {
  margin-right: 8px !important;
}

.ant-input-group {
  border-radius: 8px !important;
  height: 100%;
}

.ant-input-group > .ant-input {
  height: 100%;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.ant-input-group > .ant-input-group-addon {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.ant-input-affix-wrapper > .ant-input {
  font-weight: 500 !important;
}

.ant-select-selector {
  box-shadow: none !important;
}

.ant-picker {
  height: 38px;
  border-radius: 8px !important;
  box-shadow: none !important;
  border-color: #1d1e211f !important;
}

.ant-picker:focus-within {
  border-color: #508bef !important;
}

.ant-picker.ant-picker-status-error {
  border-color: #ff5b37 !important;
}

textarea::placeholder {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.wrap-placeholder::placeholder {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
}

pre {
  margin: unset !important;
}

.ant-radio-inner::after {
  background-color: #468aff !important;
}

.ant-table {
  overflow-x: auto;
}

/* Tab Start */

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  border-bottom: 1px solid #1d1e211f !important;
}

.ant-tabs-tab {
  padding: 4px 8px !important;
  margin-bottom: 8px !important;
  color: #30538f;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.28px;
  font-size: 14px;
}

.ant-tabs-tab:hover {
  border-radius: 6px;
  background-color: #f3f3f3 !important;
  color: #30538f !important;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin-left: 16px !important;
}

.ant-tabs-bar {
  border-bottom: 1px solid #cdd0d4 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #508bef !important;
}

.ant-tabs-ink-bar {
  background-color: #ffb54d !important;
}

.original-tabs .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: bold;
  color: #468aff !important;
}

.original-tabs .ant-tabs-ink-bar {
  background-color: #468aff !important;
}

.activity-tabs {
  padding-top: 6px !important;
}

.activity-tabs .ant-tabs-nav-operations {
  display: none !important;
}

.activity-tabs .ant-tabs-nav {
  margin-bottom: 0 !important;
}

.activity-tabs .ant-tabs-tab {
  padding: 0px 0px 12px 0 !important;
}

/* Tab End */

.menu-popover .ant-popover-inner-content {
  padding: 0 !important;
}

.ant-select-dropdown {
  width: max-content !important;
  border-radius: 7px !important;
}

.ant-select-dropdown .ant-select-item-option {
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.28px;
  text-align: left;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: black;
}

.ant-select-dropdown .ant-select-item-option-active:hover {
  color: black;
  background-color: #eef6ff;
}

/* antd Form css END */

.mention {
  background: #e8f5fa !important;
  color: #2470aa !important;
}

.tags {
  color: #0a66c2 !important;
  font-weight: 600 !important;
}

.tags:hover {
  color: #40a9ff !important;
  text-decoration: none !important;
}

.ant-tag {
  border-radius: 50px !important;
  padding: 1.5px 7px 1.5px 7px !important;
  font-size: 13px !important;
  display: flex !important;
  align-items: center !important;
  gap: 2px;
  overflow: hidden;
}

.ant-tag :first-child + span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-tag-close-icon {
  display: flex;
  align-items: center;
}

/*-- BEGIN: FCKEditor --*/
.ck .ck-placeholder:before,
.ck.ck-placeholder:before {
  color: #bfbfbf !important;
}

.ck.ck-toolbar {
  background-color: white !important;
  border-radius: 10px 10px 0px 0px !important;
  border-color: #1d1e211f !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  background-color: white !important;
  border-radius: 0px 0px 10px 10px !important;
  border-color: #1d1e211f !important;
  font-style: normal;
  font-weight: 500;
}

.ck.ck-editor__main > .ck-editor__editable:focus {
  border-color: #508bef !important;
  box-shadow: none !important;
}

/* BEGIN: FCKEditor feedpost input */
.content-editor .ck.ck-editor__main > .ck-editor__editable {
  min-height: 170px !important;
}

.large-content-editor .ck.ck-editor__main > .ck-editor__editable {
  min-height: 250px !important;
}

.description-editor .ck.ck-editor__main > .ck-editor__editable {
  min-height: 100px !important;
}

.ck .ck-balloon-panel {
  z-index: 1000000 !important;
}

/* END: FCKEditor feedpost input */

/* BEGIN: FCKEditor comment input */
.feedpost-comment-form .ck.ck-editor__main > .ck-editor__editable {
  border-radius: 8px !important;
}

.feedpost-comment-form .ck.ck-editor__top {
  display: none !important;
}

.video-comment video {
  max-height: 300px;
}

.video-comment figure > div > div {
  background-color: transparent !important;
}

/* END: FCKEditor comment input */
/*-- END: FCKEditor --*/

.library-row::-webkit-scrollbar {
  display: none;
}

figure img {
  max-width: 100%;
}

/* BEGIN Carousel styles */
.library-row .ant-carousel .slick-prev,
.library-row .ant-carousel .slick-next,
.library-row .ant-carousel .slick-prev:hover,
.library-row .ant-carousel .slick-next:hover,
.library-row .ant-carousel .slick-prev:focus,
.library-row .ant-carousel .slick-next:focus {
  color: #faf7f4 !important;
  font-size: 48px !important;
  z-index: 10 !important;
  opacity: 0 !important;
  visibility: hidden;
  -webkit-transition: opacity 600ms, visibility 600ms;
  transition: opacity 600ms, visibility 600ms;
}

.library-row .ant-carousel .slick-prev {
  left: 15px !important;
  top: 70px !important;
}

.library-row .ant-carousel .slick-next {
  right: 35px !important;
  top: 70px !important;
}

.library-row .ant-carousel:hover .slick-next,
.library-row .ant-carousel:hover .slick-prev {
  visibility: visible;
  opacity: 1 !important;
}

.alice-carousel__stage-item {
  width: auto !important;
}

/* END Carousel styles */

.stream-video-container {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 75px;
}

#stream-video {
  height: 150px;
  margin-left: -18.75px;
  transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  background-color: black !important;
}

/* Search Input */

.search-type-select .ant-select-selector {
  height: 40px !important;
  border-radius: 0px !important;
}

.search-type-select .ant-select-selection-item {
  line-height: 36px !important;
}

.content-text-wrap {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .content-text-wrap {
    font-size: 12px;
  }
}

.linked-content-description * {
  margin-bottom: 0px !important;
}

.hero-content-wrap a {
  pointer-events: none;
  text-decoration: none;
}

.hero-content-wrap * {
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 500;
  font-style: normal;
  text-align: left !important;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.scrollbar-less-container {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.scrollbar-less-container::-webkit-scrollbar {
  display: none;
}

/* .search-form > .ant-form-item-explain .ant-form-item-explain-connected {
  display: none;
} */

.search-form-item > .ant-form-item-control > .ant-form-item-explain {
  display: none !important;
}

@media (max-width: 768px) {
  .search-form-item {
    margin-bottom: unset !important;
  }
}

/* Below css is to set initial table width, needs to be sync with tableProperties.defaultProperties.width in Ckeditor5 repository */
.ck-content .table {
  width: 100%;
}

.white-text.ant-progress .ant-progress-text {
  color: white;
}

.ant-progress-line {
  display: flex !important;
  align-items: center;
}

.ck-editor__editable:not(.ck-read-only) .ck-widget_selected .ck-media__wrapper > :not(.ck-media__placeholder) {
  pointer-events: initial !important;
}

.ant-breadcrumb ol {
  flex-wrap: unset !important;
}

.ant-breadcrumb ol li {
  min-width: 70px !important;
  display: flex !important;
}

.ant-breadcrumb-link {
  display: flex !important;
  overflow: hidden !important;
}

.ant-breadcrumb a {
  color: #006dff !important;
}

.ant-breadcrumb a:hover {
  color: #006dff !important;
  font-weight: 600 !important;
}

.ant-breadcrumb li:last-child a {
  color: #006dff !important;
}

/* Query Builder Styles */

.query-builder-container .query-builder {
  margin: 0 !important;
}

.query-builder-container .group {
  border: 1px solid #ebf1fa !important;
  background: white !important;
  padding: 5px 5px 10px 5px !important;
  border-radius: 10px !important;
}

.query-tree .rule--field .ant-select:first-of-type {
  width: 213px !important;
}

.query-tree .widget--has-valuerscs .ant-select:first-of-type {
  min-width: 240px !important;
  @media (max-width: 767px) {
    min-width: 200px !important;
  }
}

.query-tree .ant-select-selector {
  padding: 4px 10px !important;
  border-radius: 10px !important;
  font-size: 14px;
  height: 38px !important;
  text-align: left;
}

.query-tree .ant-select-selection-item {
  line-height: 30px !important;
}

.query-tree .ant-select-selection-search {
  line-height: 38px !important;
}

.query-tree .ant-select-selection-placeholder {
  line-height: 30px !important;
}

.query-tree .ant-input {
  border-radius: 8px !important;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  height: 38px;
}

.query-tree .ant-input-number-in-form-item {
  border-radius: 8px !important;
  min-width: 120px;
}

.query-tree .ant-input-number-input {
  border-radius: 8px !important;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  height: 38px;
}

.query-tree .ant-select-selector .ant-select-selection-overflow {
  height: 30px;
}

.query-tree .ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 30px !important;
}

.query-tree .ant-select-multiple .ant-select-selection-item {
  margin-top: 0;
}

.query-tree .ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 30px;
  line-height: 30px !important;
}

.query-tree .rule--func--arg-sep {
  vertical-align: middle !important;
}

.query-tree .widget--sep {
  vertical-align: middle !important;
}

/* Query Builder Styles End*/

.ant-dropdown-menu-item {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.28px;
  color: black !important;
  transition: unset !important;
}
.ant-dropdown-menu-item:not(.not-menu):not(.danger-menu):hover {
  color: black !important;
  background-color: #eef6ff !important;
}

.ant-dropdown-menu-item-icon {
  color: #508bef !important;
  background-color: inherit !important;
}

.ant-dropdown-trigger:not(.not-menu):not(.danger-menu):not(.ant-avatar) {
  color: #508bef !important;
}

.danger-menu:hover {
  background-color: #ff5b37 !important;
  color: white !important;
}

.sidebar-link:hover {
  background-color: #d3e3fd;
}

.slick-slide > div {
  margin: 0 2px;
}

.slick-list {
  margin: 0 -1px !important;
}

#webpack-dev-server-client-overlay {
  display: none;
}

.ck-powered-by-balloon {
  display: none !important;
}

.menu-popover .ant-popover-placement-bottomLeft {
  padding-top: unset !important;
}

.menu-popover .ant-popover-inner {
  box-shadow: none !important;
  padding: 12px !important;
  border: solid 1px #b7b6b5 !important;
}

.ant-popover-inner {
  border-radius: 6px !important;
}

.ant-badge-dot {
  width: 8px !important;
  height: 8px !important;
  top: 50% !important;
  right: unset !important;
}

div.rounded-video {
  border-radius: 8px;
  overflow: hidden;
  background: unset !important;
  background-color: unset !important;
  width: fit-content;
  aspect-ratio: auto;
  margin: 0 auto;
}

div.rounded-video video {
  display: block;
  height: 100%;
}

div[data-oembed-url] div:first-child {
  background-color: unset !important;
}

div.ck-media__wrapper {
  text-align: center;
}

.no-margin * {
  margin-bottom: 0px !important;
}

.audio {
  width: 100%;
  text-align: center;
}

.audio audio {
  width: 70%;
  max-width: 70%;
}

@media (max-width: 560px) {
  .audio audio {
    width: 100%;
    max-width: 100%;
  }
}

.ant-tooltip-arrow-content::before {
  background: white !important;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.ant-tooltip {
  z-index: 2000 !important;
}

.ant-tooltip-inner {
  background-color: white !important;
  color: #1d1e21 !important;
  border-radius: 6px !important;
  padding: 8px 12px !important;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.08);
  font-size: 12px;
}

.ant-notification-notice {
  border-radius: 8px !important;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.08);
  padding: 16px !important;
}

.ant-notification-notice-icon {
  margin: 0 !important;
}

.ant-notification-notice-message {
  margin-left: 36px !important;
  margin-bottom: 0 !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.ant-collapse-header {
  background-color: white !important;
}

.page-container {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  animation: none;
  flex-wrap: nowrap;
  direction: ltr;
  @media (max-width: 768px) {
    display: inline-grid;
  }
}

.loading-autocomplete .ant-select-selection-placeholder {
  line-height: 38px !important;
}

#queryForm .loading-autocomplete {
  width: 400px;
  text-align: left;
}

#queryForm .fj-content-type-select {
  width: 95%;
  text-align: left;
}

@media (max-width: 767px) {
  #queryForm .loading-autocomplete {
    width: 200px;
  }

  #queryForm .fj-content-type-select {
    width: 200px;
  }
}

.ant-collapse-item {
  border-bottom: solid 2px #1d1e211a !important;
}

.ant-collapse-item:last-child {
  border: none !important;
}

.ant-collapse-item-active {
  border: none !important;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 8px 0 0 8px !important;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 8px 8px 0 !important;
}

.ant-radio-button-wrapper-checked {
  box-shadow: none !important;
}

.ant-alert {
  border-radius: 12px !important;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-upload-list-item-card-actions-btn {
  opacity: 1 !important;
}

.ant-image-mask {
  background: transparent !important;
  align-items: start !important;
  justify-content: end !important;
}

.ant-image-mask-info {
  padding: 8px !important;
}
