@font-face {
  font-family: 'Satoshi';
  src: url('Satoshi-Italic.eot');
  src: local('Satoshi Italic'), local('Satoshi-Italic'), url('Satoshi-Italic.eot?#iefix') format('embedded-opentype'),
    url('Satoshi-Italic.woff2') format('woff2'), url('Satoshi-Italic.woff') format('woff'),
    url('Satoshi-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  src: url('Satoshi-Black.eot');
  src: local('Satoshi Black'), local('Satoshi-Black'), url('Satoshi-Black.eot?#iefix') format('embedded-opentype'),
    url('Satoshi-Black.woff2') format('woff2'), url('Satoshi-Black.woff') format('woff'),
    url('Satoshi-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  src: url('Satoshi-BlackItalic.eot');
  src: local('Satoshi Black Italic'), local('Satoshi-BlackItalic'),
    url('Satoshi-BlackItalic.eot?#iefix') format('embedded-opentype'), url('Satoshi-BlackItalic.woff2') format('woff2'),
    url('Satoshi-BlackItalic.woff') format('woff'), url('Satoshi-BlackItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  src: url('Satoshi-Regular.eot');
  src: local('Satoshi Regular'), local('Satoshi-Regular'), url('Satoshi-Regular.eot?#iefix') format('embedded-opentype'),
    url('Satoshi-Regular.woff2') format('woff2'), url('Satoshi-Regular.woff') format('woff'),
    url('Satoshi-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  src: url('Satoshi-LightItalic.eot');
  src: local('Satoshi Light Italic'), local('Satoshi-LightItalic'),
    url('Satoshi-LightItalic.eot?#iefix') format('embedded-opentype'), url('Satoshi-LightItalic.woff2') format('woff2'),
    url('Satoshi-LightItalic.woff') format('woff'), url('Satoshi-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  src: url('Satoshi-Medium.eot');
  src: local('Satoshi Medium'), local('Satoshi-Medium'), url('Satoshi-Medium.eot?#iefix') format('embedded-opentype'),
    url('Satoshi-Medium.woff2') format('woff2'), url('Satoshi-Medium.woff') format('woff'),
    url('Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  src: url('Satoshi-BoldItalic.eot');
  src: local('Satoshi Bold Italic'), local('Satoshi-BoldItalic'),
    url('Satoshi-BoldItalic.eot?#iefix') format('embedded-opentype'), url('Satoshi-BoldItalic.woff2') format('woff2'),
    url('Satoshi-BoldItalic.woff') format('woff'), url('Satoshi-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  src: url('Satoshi-Bold.eot');
  src: local('Satoshi Bold'), local('Satoshi-Bold'), url('Satoshi-Bold.eot?#iefix') format('embedded-opentype'),
    url('Satoshi-Bold.woff2') format('woff2'), url('Satoshi-Bold.woff') format('woff'),
    url('Satoshi-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  src: url('Satoshi-Light.eot');
  src: local('Satoshi Light'), local('Satoshi-Light'), url('Satoshi-Light.eot?#iefix') format('embedded-opentype'),
    url('Satoshi-Light.woff2') format('woff2'), url('Satoshi-Light.woff') format('woff'),
    url('Satoshi-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  src: url('Satoshi-MediumItalic.eot');
  src: local('Satoshi Medium Italic'), local('Satoshi-MediumItalic'),
    url('Satoshi-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('Satoshi-MediumItalic.woff2') format('woff2'), url('Satoshi-MediumItalic.woff') format('woff'),
    url('Satoshi-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
