:root {
  --redis-yellow: #dcff1e;
  --redis-dusk: #163341;
  --redis-red: #ff4438;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: 'Space Grotesk', sans-serif !important;
  font-weight: 400;
}

body.redis-styles {
  font-family: 'Space Grotesk' !important;
  font-weight: 400 !important;
}

.redis-styles .custom-primary {
  background-color: var(--redis-dusk) !important;
}

.redis-styles .custom-primary:hover,
.redis-styles .custom-primary:disabled {
  background-color: var(--redis-yellow) !important;
  color: var(--redis-dusk) !important;
}

.redis-styles .custom-secondary {
  background-color: var(--redis-yellow);
  color: var(--redis-dusk) !important;
  border-color: var(--redis-yellow);
}

.redis-styles .custom-secondary:hover,
.redis-styles .custom-secondary:disabled {
  background-color: white !important;
  border-color: var(--redis-dusk);
}

/* .custom-tertiary {
  background-color: none;
  color: var(--redis-red);
  border-color: none;
}

.custom-tertiary:hover,
.custom-tertiary:disabled {
  color: var(--redis-dusk);
  border-color: var(--redis-dusk) !important;
} */
